* {
    padding: 0;
    margin: 0;
    font-synthesis: style;
    
}

html {
    font-size: 10px;
    /* 设置根元素的字体大小为10px */
}

.mobile_header {
    display: none;
}

body {
    background: #F4F4F4;
    font-family: 'HiraKakuPro-W6' ;
}

@keyframes fadeIn {
    0% {
        left: -100%;
    }

    50% {
        left: 150%;
    }

    100% {
        left: 150%;
    }
}

@keyframes jump {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-50%);
        /* 加快上升速度 */
    }
}

.box {
    width: 620px;
    margin: 0 auto;
    background: white;

    b {
        display: inline;
        box-shadow: inset 0 -3px 0 0 #FFFF00;
        /* 使用 box-shadow 模拟下划线 */
        padding-bottom: 3px;
        /* 确保下划线不会覆盖文本 */
    }
    // footer{
    //     width:100% ;
    //     height: 70px;
    //     background: black;
    //     color: white;
    //     font-size: 14px;
    //     text-align: center;
    //     line-height: 70px;
    //     cursor: pointer;
    // }

    :global {
        .animate__bounce{
            --animate-duration: 2s;
            
        }
        .top {
            padding: 31px 52px;
            text-align: center;
        }

        .top-img1 {
            width: 518px;
            height: 43px;
        }

        .div-yellow {
            text-align: center;
            clear: both;
            p {
                display: inline-block;
                font-size: 24px;
                text-align: center;
                color: #FF1D25;
                text-shadow: 6px 4px 0px #FFFFFF;
                background: #FFFF00;
                margin-top: 20px;
                font-style: oblique;
                font-family:  "HGPSoeiKakugothicUB";
            }
        }

        .top-img2 {
            width: 450px;
            height: 450px;
            margin: 0 auto;
            margin-top: 55px;
        }

        .top-text {
            font-size: 23px;
            text-align: left;
            font-weight: 400;
            font-family: "Kozuka Gothic Pr6N";
            p {
                margin: 30px 0;

            }

        }

        .span-red {
            color: #FF0000;
        }

        .top2 {
            >h2 {
                width: 440px;
                font-size: 23px;
                margin: 20px auto;
                font-family: "HGPSoeiKakugothicUB";
                text-align: center;
                
            }
        }

        .top2-img1 {
            width: 100%;
            height: 152px;
            font-weight: normal;
            font-size: 30px;
            font-family: "HGPSoeiKakugothicUB";
            color: #FFFFFF;
            text-align: center;
            line-height: 132px;
            background: url(../../assets/images/growth/top2-1.png) no-repeat center/cover;
        }

        .top2-box {
            padding: 0 17px;

            >div {
                width: 100%;
                height: auto;
                padding: 31px 24px;
                border: #FF0000 8px solid;
            }
        }

        .top2-step {
            margin-top: 48px;
        }

        .top2-step-1 {
            display: flex;
            align-items: center;

            >img {
                width: 58px;
                height: 58px;
                margin-right: 25px;
            }

            h1 {
                font-size: 39px;
                font-family: "HGPSoeiKakugothicUB";
            }
        }

        .top2-step-2 {
            display: flex;
            align-items: center;

            >img {
                width: 34px;
                height: 164px;
                margin-left: 15px;
                margin-right: 38px;
            }

            p {
                font-size: 22px;
                font-family: "Kozuka Gothic Pr6N";
            }
        }

        .top2-step-back {
            margin-left: 86px;
        }

        .top3 {
            margin-top: 150px;

            >h2 {
                width: 515px;
                font-size: 23px;
                margin: 30px auto;
                font-family: "Kozuka Gothic Pr6N";
            }
        }

        .top3-img1 {
            padding-top: 32px;
            line-height: 1.3;
        }

        .top3-box {
            padding: 0 41px;

            p {
                font-size: 23px;
                margin-top: 30px;
                font-family: "Kozuka Gothic Pr6N";
            }
        }

        .top3-img2 {
            width: 100%;
            height: 37px;
        }

        .top3-img3 {
            width: 100%;
            height: 231px;
        }

        .top3-box2 {
            margin-top: 30px;
        }

        .top3-box3 {
            font-size: 66px;
            font-family: "Kozuka Gothic Pr6N";
        }

        .top3-box4 {
            padding: 0 17px;
            margin-top: 30px;
            font-family: "Kozuka Gothic Pr6N";
            >div {
                width: 100%;
                height: auto;
                padding: 10px 34px;
                border: #999999 8px solid;
            }
        }

        .top3-box4-text {
            display: flex;
            align-items: center;
            font-size: 28px;
            margin: 20px 0;
            text-align: left;

            p {
                width: 193px;
                height: 48px;
                color: white;
                text-align: center;
                background: #999999;
            }

            span {
                display: block;
                margin-left: 20px;
            }
        }

        .top4 {
            margin-top: 50px;
        }

        .top4-box {
            padding: 0 52px;
            text-align: center;
            font-family: "Kozuka Gothic Pr6N";

            h1 {
                font-size: 52px;
                color: #FF0000;
                text-align: left;
            }

            p {
                font-size: 23px;
                text-align: left;
                margin: 24px 0;
            }
        }

        .top4-img2 {
            width: 100%;
            height: 172px;
            margin-top: 30px;
        }

        .top4-box2 {
            width: 100%;
            height: 261px;
            margin-top: 60px;
            padding-left: 48px;
            position: relative;
            background: url(../../assets/images/growth/top4-box2.png) no-repeat center/cover;

            >p {
                position: absolute;
                bottom: 0;
                font-size: 23px;
                width: 279px;
                font-family: "Kozuka Gothic Pr6N";
            }
        }

        .top4-box3 {
            padding: 0 17px;

            >div {
                border: none;
            }
        }

        .top4-box3-text {
            width: 100%;
            height: auto;
            padding: 25px 24px;
            border: #00FF05 8px solid  !important;
            margin-top: 50px;
        }

        .top4-img3 {
            width: 100%;
            height: 164px;
            margin-top: 50px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -50%;
                /* 初始位置在按钮外部 */
                width: 50%;
                height: 100%;
                background: rgba(255, 255, 255, 0.5);
                /* 半透明的白色光线 */
                transform: skewX(-20deg);
                /* 倾斜效果 */
                transition: none;
                animation: glow 2s infinite;

            }


        }

        .span-blue {
            color: #0000FF;
        }

        .h1-yellow {

            position: relative;
            display: inline-block;
            // margin-bottom: 53px;
            font-weight: bold;
            z-index: 99;

            &::after {
                content: '';
                /* 伪元素内容为空 */
                display: block;
                position: absolute;
                /* 绝对定位 */
                bottom: 6px;
                left: 0;
                width: 100%;
                height: 30px;
                opacity: .8;
                z-index: -1;
                background: #FFFF00;
            }
        }

        .top5 {
            margin-top: 100px;
        }

        .top5-box {
            padding: 0 61px;
            padding-bottom: 20px;
            font-family: "Kozuka Gothic Pr6N";
            hr {
                height: 8px;
                background: #999;
            }
        }

        .top5-box-text {
            h1 {
                font-size: 31px;
                color: #0000F4;
                margin: 50px 0;
            }

            p {
                font-size: 23px;
                margin: 30px 0;
            }
        }


    }
}

.flash {
    width: 100%;
    margin-top: 50px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    img {
        width: 100%;
        height: 164px;
    }
    &::after {
        position: absolute;
        left: -100%;
        /*改变left的值，让其相对box影藏*/
        top: 0;
        width: 30%;
        height: 100%;
        content: "";
        /* Safari 5.1 - 6.0 */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, 0) 100%);
        /* Opera 11.1 - 12.0 */
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, 0) 100%);
        /* Firefox 3.6 - 15 */
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, 0) 100%);
        /* 标准的语法 */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, 0) 100%);
        transform: skewX(-45deg);
        animation: fadeIn 2s infinite;

    }

}


.information {
    padding: 95px 0;

    :global {
        .basic {
            width: 100%;
            margin: 0 auto;

            .basic-title {
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                >img {
                    width: 32px;
                    height: 63px;
                }

                >div {
                    width: auto;
                    text-align: center;
                    font-size: 23px;
                    color: #1E3995;
                    display: inline-block;
                    border-bottom: #2E4A9D 3px solid;
                }
            }

            .basic-box {
                ul {
                    margin-top: 69px;
                    border-bottom: 1px solid #898988;
                    border-top: 1px solid #898988;
                    height: 596px;
                    li {
                        list-style-type: none;
                        padding: 10px 20px;
                        border-bottom: 1px dashed #898988;
                        font-size: 17px;
                        color: #7A7A7A;
                        position: relative;
                        text-align: left;
                        span {
                            padding-left: 40px;
                            text-align: left;
                            border-left: 1px solid #898988;
                            display: inline-block;
                            position: absolute;
                            left: 240px;
                        }
                    }
                    li:last-child{
                        border-bottom: none; 
                    }
                }
            }
        }
    }
}

.br {
    display: none;
}

.pr{
    color: #999;
    font-size: 12px;
    float: right;
}








@media screen and (max-width: 768px) {
    .br {
        display: block;
    }
    .box {
        width: 100%;

        b {
            display: inline;
            box-shadow: inset 0 -3px 0 0 #FFFF00;
            /* 使用 box-shadow 模拟下划线 */
            padding-bottom: 3px;
            /* 确保下划线不会覆盖文本 */
        }

        :global {
            .top {
                padding: 7.6vw 6.933vw;
                text-align: center;
            }

            .top-img1 {
                width: 100%;
                height: 7.6vw;
            }

            .div-yellow {
                text-align: center;

                p {
                    display: inline-block;
                    font-size: 4.2vw;
                    text-align: center;
                    color: #FF1D25;
                    text-shadow: 0.8vw 0.533vw 0vw #FFFFFF;
                    background: #FFFF00;
                    margin-top: 3vw;
                }
            }

            .top-img2 {
                width: 100%;
                height: 86.133vw;
                margin: 0 auto;
                margin-top: 2.333vw;
            }

            .top-text {
                font-size: 4vw;
                text-align: left;
                font-weight: 400;

                p {
                    margin: 4vw 0;

                }

            }

            .span-red {
                color: #FF0000;
            }

            .top2 {
                >h2 {
                    //width: 72.267vw;
                    width: 100%;
                    font-size: 4.2vw;
                    margin: 3vw auto;
                    text-align: center;
                }
            }

            .top2-img1 {
                width: 100%;
                height: 24.8vw;
                font-size: 5vw;
                line-height: 22.8vw;
                background: url(../../assets/images/growth/top2-1.png) no-repeat center/cover;
            }

            .top2-box {
                padding: 0 2.533vw;

                >div {
                    width: 100%;
                    height: auto;
                    padding: 4.933vw 4.667vw;
                    border: #FF0000 1.067vw solid;
                }
            }

            .top2-step {
                margin-top: 6.4vw;
            }

            .top2-step-1 {
                display: flex;
                align-items: center;

                >img {
                    width: 8vw;
                    height: 8vw;
                    margin-right: 4.133vw;
                }

                h1 {
                    font-size: 4.7vw;
                }
            }

            .top2-step-2 {
                display: flex;
                align-items: center;

                >img {
                    width: 8vw;
                    height: 32.267vw;
                    margin-left: 0.6vw;
                    margin-right: 5.733vw;
                }

                p {
                    font-size: 4vw;
                }
            }

            .top2-step-back {
                margin-left: 12.333vw;
            }

            .top3 {
                margin-top: 20vw;

                >h2 {
                    width: 84.8vw;
                    font-size: 4vw;
                    margin: 3.667vw auto;
                }
            }

            .top3-img1 {
                padding-top: 3.467vw;
                line-height: 1.3;
            }

            .top3-box {
                padding: 0 4.533vw;

                p {
                    font-size: 3.7vw;
                    margin-top: 4vw;
                }
            }

            .top3-img2 {
                width: 100%;
                height: 6.4vw;
            }

            .top3-img3 {
                width: 100%;
                height: 39.067vw;
                margin-top: 2vw;
            }

            .top3-box2 {
                margin-top: 6.667vw;
            }

            .top3-box3 {
                font-size: 8.8vw;
            }

            .top3-box4 {
                padding: 0 2.533vw;
                margin-top: 3.667vw;

                >div {
                    width: 100%;
                    height: auto;
                    padding: 2.133vw 1.867vw;
                    border: #999999 1.067vw solid;
                }
            }

            .top3-box4-text {
                display: flex;
                align-items: center;
                font-size: 4.667vw;
                margin: 3.667vw 0;
                text-align: left;

                p {
                    width: 31.867vw;
                    height: 8vw;
                    color: white;
                    text-align: center;
                    background: #999999;
                }

                span {
                    display: block;
                    margin-left: 2.667vw;
                }
            }

            .top4 {
                margin-top: 6.667vw;
            }
            .p-bold{
                font-weight: bold;
                font-size: 5vw !important;
            }

            .top4-box {
                padding: 0 6.267vw;
                text-align: center;

                h1 {
                    font-size: 8vw;
                    color: #FF0000;
                    text-align: left;
                }

                p {
                    font-size: 4vw;
                    text-align: left;
                    margin: 4vw 0;
                }
            }

            .top4-img2 {
                width: 100%;
                height: 28.4vw;
                margin-top: 3.667vw;
            }

            .top4-box2 {
                width: 100%;
                height: 44.267vw;
                margin-top: 6vw;
                padding-left: 5.333vw;
                position: relative;
                background: url(../../assets/images/growth/top4-box2.png) no-repeat center/cover;

                >p {
                    position: absolute;
                    bottom: 0;
                    font-size: 4vw;
                    width: 48.133vw;
                }
            }

            .top4-box3 {
                padding: 0 6.667vw;
                >div {
                    border: none;
                }
            }

            .top4-box3-text {
                width: 100%;
                height: auto;
                padding: 2.133vw 6vw;
                border: #00FF05 1.067vw solid !important;
                margin-top: 13.333vw;
            }

            .top4-img3 {
                width: 100%;
                height: 18.667vw;
                margin-top: 6.6vw;
            }

            .span-blue {
                color: #0000FF;
            }

            .h1-yellow {

                position: relative;
                display: inline-block;
                // margin-bottom: 7.067vw;
                font-weight: bold;
                z-index: 99;

                &::after {
                    content: '';
                    /* 伪元素内容为空 */
                    display: block;
                    position: absolute;
                    /* 绝对定位 */
                    bottom: 1.267vw;
                    left: 0;
                    width: 100%;
                    height: 2.667vw;
                    opacity: .8;
                    z-index: -1;
                    background: #FFFF00;
                }
            }

            .top5 {
                margin-top: 13.333vw;
            }

            .top5-box {
                padding: 0 3.733vw;
                padding-bottom: 2.667vw;
                hr {
                    height: 2.8vw;
                    background: #999;
                }
            }

            .top5-box-text {
                h1 {
                    font-size: 5.333vw;
                    color: #0000F4;
                    margin: 6.333vw 0;
                }

                p {
                    font-size: 4vw;
                    margin: 3.667vw 0;
                }
            }

        }
    }
    .information {
        padding: 12.667vw 4vw;

        :global {
            .basic {
                width: 100%;
                margin: 0 auto;

                .basic-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;

                    >img {
                        width: 4.267vw;
                        height: 8.4vw;
                    }

                    >div {
                        width: auto;
                        text-align: center;
                        font-size: 7.467vw;
                        color: #1E3995;
                        display: inline-block;
                        border-bottom: #2E4A9D 0.4vw solid;
                    }
                }

                .basic-box {
                    ul {
                        margin-top: 9.2vw;
                        border-bottom: 0.133vw solid #898988;
                        border-top: 0.133vw solid #898988;
                        height: auto;
                        li {
                            list-style-type: none;
                            padding: 1.333vw 2.667vw;
                            border-bottom: 0.133vw dashed #898988;
                            font-size: 3.733vw;
                            color: #7A7A7A;
                            position: relative;
                            display: grid;
                            grid-template-columns: 30vw 1fr;
                            text-align: left;

                            span {
                                padding-left: 3.333vw;
                                text-align: center;
                                border-left: 0.133vw solid #898988;
                                display: inline-block;
                                position: static;
                                left: 33.333vw;
                                flex-grow: 1;
                                text-align: left;
                            }
                        }
                        li:last-child{
                            border-bottom: none; 
                        }
                    }
                }
            }
        }
    }
}